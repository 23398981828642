import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import SponsorsCarousel from "../components/sponsors-carousel"

export default function Sponsors() {
  return ( 
    <Layout>
        <section className="container">
        <h1>Sponsors</h1>
        <InnerPageTwitterLayout>
            <img src="sponsors-wanted.jpg" className="img-fluid mb-4 inner-page-hero" />
            <p className="lead">Sponsorship is an excellent way for the club to gain revenue whilst raising the profile of the organisation that's sponsoring us.</p>
            <p>There are huge benefits to sponsoring a grassroots football team, from providing your local team with much needed financial backing, to raising your businesses profile and increasing local brand awareness. There is also a Tax benefit to sponsoring local sports teams, if you can prove that the purpose and benefit are 'wholly and exclusively' business related. We would always advise speaking to your accountant for more information.</p>
            <h3>What are the benefits to sponsoring the club?</h3>
            <h6>CONTRIBUTION TO THE COMMUNITY</h6>
            <p>Your contribution is greatly appreciated by the club, players and parents. We find that this results in building trust and loyalty from the community.</p>
            <h6>EXPOSURE TO LOCAL / ENGAGED MARKET</h6>
            <p>We have a very active and engaged fan base of people from the local areas.</p>
            <h6>ON-LINE VISIBILITY</h6>
            <p>Your logo and brand will have a prominent position on our website, home page and team pages.</p>
            <h6>MONTHLY FEATURES IN NEWSLETTER</h6>
            <p>Your branding and message will be featured as a club sponsor in our monthly newsletter to our list of members.</p>

            <h3>Want to know more or get involved?</h3>
            <p>For more information please <Link to="/contact">contact us</Link> or if you are interested in sponsoring a particular team please see the contact information on the relevant team page.</p>

            <div className="card text-center mb-4">
              <div className="card-header">
                Our Current Sponsors
              </div>
              <div className="card-body">
                <SponsorsCarousel></SponsorsCarousel>
              </div>
            </div>
        </InnerPageTwitterLayout>
      </section>
    </Layout>
  );
}
